import Api from './Api';

const checkIndex = (payload) => {
    return Api.postRequest({
        endpoint: '/is-indexed-by-google',
        data: payload
    });
};

export default {
    checkIndex
}